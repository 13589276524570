import Swal from "sweetalert2"

export default {
    namespaced: true,
    state: {
        //api: "http://192.168.100.242:8000/api/",
        api: "https://intranet-api.clinicamadrezoraida.com.pe/api/",
        //api: "https://apitestccc.cetemin.edu.pe/api/",
        optionAxios: {
            url: "",
            method: "",
            headers: {
                'content-type': "application/json",
                Accept: "application/json",
                Authorization: ""
            },
            params: null,
            data: null
        },

        sedes: [
            {
                title: "Chosica",
                value: 'chosica'
            },
            {
                title: "Lima",
                value: 'lima'
            }
        ]
    },

    mutations: {

        alertLoading() {
            Swal.fire({
                text: "Cargando la solicitud",
                icon: "info",
                showConfirmButton: false,
                allowOutsideClick: false,
                width: "300px"
            })
        },

        alertConfirm(state, textconfirm) {
            Swal.fire({
                text: textconfirm,
                icon: "success",
                width: "300px",
                timer: 2000
            })
        },

        alertError(state) {
            Swal.fire({
                text: "Ha ocurrido un error",
                icon: "error",
                width: "300px",
            })
        },


        alertWarning(state, textwarnin) {
            Swal.fire({
                text: textwarnin,
                icon: "warning",
                width: "300px",
                timer: 2000
            })
        },



    }
}